@import "app/styles/colors";
@import "app/styles/fonts";
@import "app/styles/screens";
@import "app/styles/layout";
@import "app/styles/z-indexes";
@import "app/styles/misc";
@import "app/styles/mixins";

$inner-width: 1140px;
$row-gap: 30px;
$field-gap: 30px;

.layout {
  display: flex;

  @include medium-and-large {
    flex-flow: column nowrap;
    align-items: center;
  }
}

.header {
  display: flex;
  background-color: $gray-extra-light;
  padding: 0 150px;

  @include medium-and-large {
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
  }
}

.headerInner {
  display: flex;
  width: 100%;

  @include medium-and-large {
    flex-flow: column nowrap;
  }
}

.headerTitle {
  display: flex;

  @include medium-and-large {
    flex-flow: row wrap;
    width: 100%;
    padding: 50px 0 25px;
  }
}

.headerMenuPlaceholder {
  display: grid;
  grid-template-rows: 40px;
}

$progress-line-height: 4px;

.headerProgressLine {
  display: grid;
  width: 100%;
  height: $progress-line-height;
  background-color: $gray-lightest;
}

.headerProgressLineInner {
  display: grid;
  position: relative;
  width: 100%;

  @include medium-and-large {
    grid-template-columns: 1fr 1fr 1fr;
    height: $progress-line-height;
  }
}

.headerMenuItem {
  display: grid;
  position: relative;
}

.headerMenuItemLabel {
  position: absolute;
  bottom: 0;
  border-bottom: $progress-line-height solid transparent;
  padding-bottom: 6px;
  font-size: 0.95em;
  font-weight: $font-weight-bold;
}

.headerMenuItemLabelActive {
  border-bottom-color: $purple;
}

.headerMenuItemLabelLink {
  &:hover {
    border-bottom-color: lighten($purple, 20%);
  }

  & a,
  & a:hover {
    color: inherit;
  }
}

.form {
  display: grid;
  padding: 0 150px;

  @include medium-and-large {
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    grid-row-gap: $row-gap;
    margin-bottom: 90px;
  }
}

.formTitle {
  display: flex;

  @include medium-and-large {
    margin: 50px 0 10px;
  }
}

.fieldGroup_md_lg {
  @include medium-and-large {
    display: grid;
    grid-column-gap: $field-gap;
  }
}

.fieldGroup1_md_lg {
  @include medium-and-large {
    grid-template-columns: 1fr;
  }
}

.fieldGroup2_md_lg {
  @include medium-and-large {
    grid-template-columns: 1fr 1fr;
  }
}

.fieldGroup3_md_lg {
  @include medium-and-large {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.fieldGroupAuto_md_lg {
  @include medium-and-large {
    grid-auto-flow: column;
  }
}

.field {
  display: flex;
  flex-flow: column nowrap;
}

.kwField {
  position: relative;

  &::after {
    display: flex;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: $gray-light;
    font-size: 0.9em;
    content: "kw";
  }
}

.featureWrapper {
  display: grid;
  position: relative;
  align-items: center;
}

.featureCounter {
  position: absolute;
  left: -40px;
  font-size: 30px;
  font-weight: $font-weight-bold;
}

.featureDeleteIcon {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: -20px;
  transform: translateX(100%);
}

.featureDeleteIconControl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  &:focus,
  &:hover {
    path {
      fill: $teal;
    }
  }
}

.featureFields {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-template-columns: 1fr;
  grid-row-gap: $row-gap;
  padding: 40px;
  background-color: $gray-extra-light;
}

.featuresButton {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 20px;
  align-items: center;
}

.priceFieldGroup {
  display: grid;

  @include medium-and-large {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
  }
}

.note {
  color: $gray-light;
}

.error {
  margin-top: 7px;
}

.actionBar {
  display: grid;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $z-index-dashboard-product-editor-action-bar;
  background-color: #fff;
}

.actionBarInner {
  display: grid;
  grid-template-areas: "ab-left-btn . ab-right-btn";
  grid-template-columns: minmax(max-content, 200px) 1fr minmax(max-content, 200px);
  padding: 30px;
  border-top: 1px solid $gray-lighter;
}

.actionBarLeftButton {
  grid-area: ab-left-btn;
}

.actionBarRightButton {
  grid-area: ab-right-btn;
}

.actionBarNote {
  display: grid;
  align-items: center;
  justify-content: center;
  color: $gray-light;
}

.actionBarLeftSideControlsContainer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content;
  grid-column-gap: 20px;
  align-items: center;
}

.actionBarError {
  display: grid;
  align-items: center;
  justify-content: center;
  color: $pink;
}

.locationsContainer {
  width: 100%;
  padding: 0 150px;
}

.locations {
  display: grid;
  margin: 50px 0;
}

.locationWidget {
  display: flex;
  margin: 0 0 12px;
  border: 1px solid $gray-lightest;
  border-radius: 3px;
  transition: box-shadow $transition-duration $transition-timing-function;
  text-align: left;
  background: none;
  appearance: none;
  cursor: pointer;

  &:focus,
  &:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  }

  &:focus {
    border-color: $teal;
  }
}

.locationWidgetInner {
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: max-content 1fr max-content;
  grid-column-gap: 14px;
  align-items: center;
  padding: 12px 20px;
  width: 100%;
}

.locationWidgetInfo {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-template-columns: max-content;
}

.locationAddress {
  color: $gray;
  font-size: 0.85em;
}

.locationDetailsLink {
  display: inline-flex;
  color: $teal;
  border-bottom: 1px dotted $teal;
  line-height: 1.2;
  cursor: pointer;

  &:focus {
    @include outline;
    margin: -3px;
    padding: 3px;
  }
}

.locationIcon {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background-color: $gray-extra-light;
  border-radius: 50%;
  padding: 30px;
}

.noLocations {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-template-columns: minmax(min-content, 450px);
  grid-row-gap: 22px;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 100px 20px;
  text-align: center;
}

.uploader {
  display: grid;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;
  height: 180px;
  border: 1px dashed $gray-lighter;
  border-radius: 6px;
  font-weight: $font-weight-bold;
}

.uploaderInner {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-row-gap: 14px;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

.assetsVideoLinkForm {
  display: grid;
  grid-template-rows: max-content max-content max-content;
  margin-bottom: 40px;
}

.assetsVideoLinkFormRow {
  display: grid;
  grid-template-columns: 500px max-content max-content;
  grid-column-gap: 12px;
}

.assetsVideoLinkFormSubmitted {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 10px;
  align-content: center;
  color: $teal;
}

.assetsVideoLinkFormError {
  margin-top: 5px;
  color: $pink;
}

.assetsGrid {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}

.assetGridItem {
  display: flex;
  position: absolute;
  flex-flow: column nowrap;
  border: 1px solid $gray-lightest;
  border-radius: 4px;
}

.assetGridItemTail {
  display: grid;
  position: absolute;
  border: 1px dashed $gray-lightest;
  border-radius: 4px;
}

.assetGridItemTailInner {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-row-gap: 12px;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
}

.assetGridItemTailInnerText {
  color: $gray-light;
}

.assetGridItemThumb {
  position: relative;
  overflow: hidden;

  .assetGridItemDeleteControl {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    transition: "background-color" $transition-duration $transition-timing-function;
    background-color: transparent;
    border-radius: 50%;

    &:hover {
      background-color: $pink;
    }
  }

  &:hover .assetGridItemDeleteControl {
    display: flex;
  }
}

$description-conrainer-padding: 10px;

.assetGridItemDescription {
  position: relative;
  padding: 10px;
  background-color: #fff;
}

.assetGridItemDescriptionField {
  padding: 8px 80px 8px 10px;
  font-size: 14px;
}

.assetGridItemDescriptionFieldError {
  color: $pink;
  border-color: $pink;
}

.assetGridItemDescriptionButton {
  position: absolute;
  top: $description-conrainer-padding;
  bottom: $description-conrainer-padding;
  right: $description-conrainer-padding;

  button {
    font-size: 14px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 18px !important;
    padding-right: 18px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.assetGridItemDeleteMessageOverlay {
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  grid-template-rows: max-content max-content;
  grid-row-gap: 12px;
  align-content: center;
  justify-content: center;
  justify-items: center;
  color: #fff;
  background-color: $semi-transparent-dark-bg;
}

.assetGridItemDeleteMessageText {
  font-weight: $font-weight-bold;
}

.assetGridItemDeleteMessageButtons {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 10px;
}

.blur {
  filter: blur(5px);
}

.uploader {
  display: grid;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;
  height: 180px;
  border: 1px dashed $gray-lighter;
  border-radius: 6px;
  font-weight: $font-weight-bold;
}

.uploaderInner {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-row-gap: 14px;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

.imagesGrid,
.videosGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.imageContainer,
.videoContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-lighter;
  border-radius: 4px;
}

.deleteAsset {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  height: 100%;
  width: 100%;
  font-weight: 600;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
    color: $white;
    opacity: 100%;
  }
}

.deleteConfirmationOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: $semi-transparent-dark-bg;
  z-index: $z-index-navbar;
}

.deleteConfirmationDialog {
  width: 300px;
  height: 170px;
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  text-align: center;
}

.deleteConfirmationText {
  font-weight: $font-weight-bold;
  color: $black;
  font-size: 22px;
}

.deleteConfirmationMessage {
  color: $gray-light;
}

.deleteConfirmationButtons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.addAssetsContainer {
  width: 100%;
  padding: 40px 150px 120px 150px;
}

.assetOverlay {
  position: relative;

  .image img {
    width: 100%;
  }
}

.assetOverlayYoutube {
  position: relative;
  max-width: 317px;
  overflow: hidden;

  .youtubeImage {
    height: 317px;
  }
}

.assetDescription {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.assetInput,
.descriptionContainer {
  width: 100%;
}

.toggleContainer {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: row nowrap;
  justify-content: flex-end;
}
